import App from "../../services/App/App.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleAttr from "../../services/App/AppModuleAttr.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import SlsEnum from "./SlsEnum.js";
import SlsItemCatalog from "./SlsItemCatalog.js";
import ItemModel from "../Item/ItemModel.js";

export default {
  // required in model
  Module: AppModule.Sales,
  ModuleType: {
    Delete: AppModuleType.Delete,
    Details: AppModuleType.Details,
    Edit: AppModuleType.Edit,
    ExportDetails: AppModuleType.ExportDetails,
    ExportList: AppModuleType.ExportList,
    List: AppModuleType.List,
    New: AppModuleType.New,
    PrintDetails: AppModuleType.PrintDetails,
    PrintList: AppModuleType.PrintList,
    SearchList: AppModuleType.SearchList
  },

  /*** related ***/

  SalesItem: {
    Module: AppModule.Item,
    ModuleType: {
      New: AppModuleType.New,
      List: AppModuleType.List,
      SearchList: AppModuleType.SearchList
    }
  },

  // other
  Client: {
    Module: AppModule.Client
  },

  Invoice: {
    Module: AppModule.Invoice,
    ModuleType: {
      List: AppModuleType.List,
      New: AppModuleType.New
    }
  },

  /*** property ***/

  Access: {
    Act_Delete: AppModuleAttr.Sales_Act_Delete,
    Act_Details: AppModuleAttr.Sales_Act_Details,
    Act_Edit: AppModuleAttr.Sales_Act_Edit,
    Act_ExportDetails: AppModuleAttr.Sales_Act_ExportDetails,
    Act_ExportList: AppModuleAttr.Sales_Act_ExportList,
    Act_List: AppModuleAttr.Sales_Act_List,
    Act_New: AppModuleAttr.Sales_Act_New,
    Act_PrintDetails: AppModuleAttr.Sales_Act_PrintDetails,
    Act_PrintList: AppModuleAttr.Sales_Act_PrintList,
    // related
    Invoice_Act_List: AppModuleAttr.Invoice_Act_List
  },

  Actions: {
    Details: [
      AppModuleType.Edit,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails,
      AppModuleType.Delete
    ],
    List: [
      AppModuleType.Details,
      AppModuleType.Edit,
      AppModuleType.PrintDetails,
      AppModuleType.ExportDetails,
      AppModuleType.Delete
    ]
  },

  DiscType: SlsEnum.DiscType,
  IsSelfService: SlsEnum.IsSelfService,

  Search: {
    ListFields: ["DraftNumber", "PONumber"],
    ListParams: ["Search", "ClientID", "ClientName", "Date", "StatusID",
      "WarehouseID"],
    SearchListFields: ["DraftNumber", "PONumber"]
  },

  Status: SlsEnum.Status,

  /*** method ***/

  createDetails(discTypeEnum) {
    return {
      ID: null,
      // user input
      WarehouseID: null,
      DraftNumber: "",
      Date: App.In.getDateToday(),
      ClientID: null,
      ClientName: "",
      ClientAddress: "",
      DiscValue: "",
      DiscPercent: "",
      PONumber: "",
      Comment: "",
      IsAutoNumber: true,
      DiscType: discTypeEnum.None.ID,
      // by system
      WarehouseName: null,
      ClientAlias: "",
      InvoiceCount: 0,
      // computed
      TotalBruto: 0,
      TotalDisc: 0,
      Total: 0,
      // UI validation
      ClientDiscPercent_MessageCredit: 0 // indicator for showing field message
    };
  },
  createItem() {
    return {
      ID: null,
      // user input
      DispatchID: null,
      DispatchName: "",
      PackagingName: "",
      Qty: "",
      SellPrice: "",
      IsClosed: false,
      // by system
      PackagingValue: 1,
      SKU: "",
      QtySalesOrder: 0,
      StockQty: 0,
      DispatchIsActive: true,
      DispatchSellPrice: 0,
      PackagingOptions: null,
      // computed
      Packaging: "",
      TotalQty: 0,
      Total: 0,
      Stock: 0,
      // error
      Errors: [],
      ErrorsColl: {},
      // UI validation
      Qty_Valid: true,
      Qty_vsDeleted: true,
      Qty_vsInvoice: true
    }
  },

  createIsSelfServiceOptions(optionAllText, isSelfServiceEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [isSelfServiceEnum.Yes, isSelfServiceEnum.No];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },
  createStatusOptions(optionAllText, statusEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [statusEnum.Open, statusEnum.Closed];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },
  createDiscTypeOptions(optionAllText, discTypeEnum) {
    const rowId = "ID";
    const rowLabel = "Label";

    const items = [discTypeEnum.None, discTypeEnum.Value, discTypeEnum.Percent];

    // set: list options
    let listOptions = { id: rowId, label: rowLabel };

    if (optionAllText) {
      listOptions.allActive = true;
      listOptions.allText = optionAllText;
    }

    // create: select rows
    const selectItems = App.Search.createList(items, listOptions);

    // create: select options
    return App.In.getSelectOptions(rowId, rowLabel, selectItems);
  },

  setDetailsByClient(userData, clientData, discTypeEnum) {
    // user input
    userData.ClientID = clientData.ID;
    userData.ClientName = App.In.getString(clientData.Name);
    userData.ClientAddress = App.In.getString(clientData.Address);
    // by system
    userData.ClientAlias = clientData.Alias;

    if (clientData.DiscPercent > 0) {
      userData.ClientDiscPercent_MessageCredit = 2;
      userData.DiscType = discTypeEnum.Percent.ID;
      userData.DiscPercent = App.In.getDecimal(clientData.DiscPercent);
    }
  },
  setDetailsByStatus(userData, statusEnum) {
    userData.StatusName = App.Data.getStatusLabel(userData.StatusID, statusEnum);
  },
  normalizeDetailsByDisc(userData) {
    userData.DiscPercent = App.In.getDecimal(userData.DiscPercent);
  },

  setItemErrors(userItem, field, errors) {
    // populate error in current field
    userItem.ErrorsColl[field] = [];

    for (const error of errors) {
      if (error !== App.Vee.ValidRule) {
        userItem.ErrorsColl[field].push(error);
      }
    }

    // populate error in all fields
    let fieldErrors, newErrors = [];
    for (const fieldName in userItem.ErrorsColl) {
      fieldErrors = userItem.ErrorsColl[fieldName];

      if (Array.isArray(fieldErrors)) {
        newErrors = newErrors.concat(fieldErrors);
      }
    }

    // custom error message
    if (!userItem.Qty_Valid) {
      if (!userItem.Qty_vsDeleted) {
        newErrors.push(SlsItemCatalog.DispatchID.Label + " tidak aktif, mohon dihapus");
      }

      if (!userItem.Qty_vsInvoice) {
        newErrors.push("Minimum nilai " + SlsItemCatalog.Qty.Label + " adalah " +
          App.Value.getValue("QtySalesOrder", userItem, SlsItemCatalog) + " " +
          "(" + SlsItemCatalog.QtySalesOrder.Label + ")"
        );
      }
    }

    userItem.Errors = newErrors;
  },
  setItemByPrice(userItem, priceData) {
    // user input
    userItem.PackagingName = priceData.PackagingName;
    userItem.SellPrice = App.In.getInteger(priceData.SellPrice);
    // by system
    userItem.PackagingValue = priceData.PackagingValue;

    for (const packaging of userItem.PackagingOptions.rows) {
      if (packaging.Name === userItem.PackagingName) {
        userItem.DispatchSellPrice = packaging.SellPrice;
        break;
      }
    }
  },
  setItemByStock(userItem, stockData, itemData) {
    userItem.StockQty = stockData.QuantityPcs;

    // user input
    userItem.DispatchID = itemData.ID;
    userItem.DispatchName = itemData.Name;
    userItem.PackagingName = itemData.DefaultPackagingName;
    userItem.SellPrice = App.In.getInteger(itemData.DefaultSellPrice);
    // by system
    userItem.PackagingValue = itemData.DefaultPackagingQty;
    userItem.SKU = itemData.SKU;
    userItem.DispatchSellPrice = itemData.DefaultSellPrice;
    userItem.PackagingOptions = ItemModel.createPackagingOptions(itemData.PackagingList);
  },

  clearDetailsByClient(userData) {
    userData.ClientID = null;
    userData.ClientName = "";
    userData.ClientAddress = "";
    userData.ClientAlias = "";
  },

  updateDetails(userData, userItems, discTypeEnum) {
    let total = 0;

    // TotalBruto
    for (const item of userItems) {
      total += item.Total;
    }
    userData.TotalBruto = total;

    // TotalDisc
    if (userData.DiscType === discTypeEnum.Value.ID) {
      userData.TotalDisc = App.JS.parseInt(userData.DiscValue);
      total -= userData.TotalDisc;
    }
    else if (userData.DiscType === discTypeEnum.Percent.ID) {
      userData.TotalDisc = App.Data.getDiscPercent_Value(total, userData.DiscPercent);
      total -= userData.TotalDisc;
    }

    // Total
    userData.Total = total;
  },
  updateItem(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
    userItem.Stock = App.Data.getQtyByPackaging(
      userItem.StockQty, userItem.PackagingValue
    );
    userItem.TotalQty = App.Data.getTotalQty(
      userItem.Qty, userItem.PackagingValue
    );
    userItem.Total = App.Data.getTotal(userItem.Qty, userItem.SellPrice);
  },
  updateItemByPackaging(userItem) {
    if (userItem.PackagingOptions) {
      const packagingData = ItemModel.getPackagingData(
        userItem.PackagingOptions.rows, userItem.PackagingName
      );

      if (packagingData) {
        // user input
        userItem.SellPrice = App.In.getInteger(packagingData.SellPrice);
        // by system
        userItem.PackagingValue = packagingData.Qty;
        userItem.DispatchSellPrice = packagingData.SellPrice;
      }
    }
  },
  updateItemReadOnly(userItem) {
    userItem.Packaging = App.Data.getPackaging(
      userItem.PackagingName, userItem.PackagingValue
    );
  },

  populateDetails(userData, discTypeEnum) {
    return {
      ID: userData.ID,
      WarehouseID: userData.WarehouseID,
      DraftNumber: userData.IsAutoNumber
        ? null : App.Out.getString(userData.DraftNumber),
      Date: App.Out.getDateString(userData.Date),
      ClientID: userData.ClientID,
      ClientName: App.Out.getString(userData.ClientName),
      ClientAddress: App.Out.getString(userData.ClientAddress),
      DiscValue: userData.DiscType === discTypeEnum.Value.ID
        ? App.Out.getInteger(userData.DiscValue) : null,
      DiscPercent: userData.DiscType === discTypeEnum.Percent.ID
        ? App.Out.getDecimal(userData.DiscPercent) : null,
      PONumber: App.Out.getString(userData.PONumber),
      Comment: App.Out.getString(userData.Comment)
    }
  },
  populateItems(userItems) {
    let resultItems = [];

    for (const item of userItems) {
      resultItems.push({
        ID: item.ID,
        DispatchID: item.DispatchID,
        DispatchName: App.Out.getString(item.DispatchName),
        PackagingName: item.PackagingName,
        PackagingValue: item.PackagingValue,
        Qty: App.Out.getInteger(item.Qty),
        SellPrice: App.Out.getInteger(item.SellPrice),
        IsClosed: App.Out.getBoolean(item.IsClosed)
      });
    }

    return resultItems;
  }
}