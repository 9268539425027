<template>
  <kst-page-edit
    @init="handleInit"
    @submit="handleSubmit"
  >
    <Details
      :userData="userData"
      :userItems="userItems"
    />
    <Items
      :userData="userData"
      :userItems="userItems"
    />

    <div class="columns">
      <div class="column">
        <Details2
          :userData="userData"
          :userItems="userItems"
        />
      </div>
      <div class="column">
        <Total :data="userData"/>
      </div>
    </div>
  </kst-page-edit>
</template>

<script>
import InputPageMixin from "../../../mixins/InputPageMixin.js";
import Model from "../SlsModel.js";
import Services from "../../../services/Api/SalesServices.js";

import Details from "./SlsE_Details.vue";
import Details2 from "./SlsE_Details2.vue";
import Items from "./SlsE_Items.vue";
import Total from "./SlsE_Total.vue";

export default {
  components: {
    Details,
    Details2,
    Items,
    Total
  },
  mixins: [InputPageMixin],
  data: () => ({
    userData: {},
    userItems: []
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadEdit) {
      this.resetData();

      const config = Services.getEditData(id);
      loadEdit(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      Model.Edit.setItemsByData(this.userItems, data.Details.Items);
      Model.Edit.setDetailsByData(this.userData, data.Details,
        data.InvoiceCount
      );
      Model.updateDetails(this.userData, this.userItems);
    },

    handleSubmit(saveData) {
      let data = Model.populateData(this.userData, this.userItems);
      const config = Services.putData(data);
      saveData(config, this.handleSubmit_Success);
    },
    handleSubmit_Success() {
      this.disableRouteLeave();
    },

    /*** custom ***/

    resetData() {
      this.userData = Model.createDetails();
      this.userItems = [];
    }
  }
}
</script>