import App from "../App/App.js";
import config from "./_config.json";
var apiEndpoint = App.Env.Api + config.sales;

export default {
  deleteData(id) {
    return {
      method: App.Enum.Axios.Method.Delete,
      url: apiEndpoint + "?id=" + id
    };
  },
  getDeleteData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/deletedata?id=" + id
    };
  },
  getDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/detailsdata?id=" + id
    };
  },
  getEditData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/editdata?id=" + id
    };
  },
  getExportDetails(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportdetails?id=" + id
    };
  },
  getExportList(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field", "withitems", "withitemsbyclient"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/exportlist" + urlParams
    };
  },
  getList(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/list" + urlParams
    };
  },
  getListData(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/listdata" + urlParams
    };
  },
  getNewData() {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/newdata"
    };
  },
  getPrintDetailsData(id) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printdetailsdata?id=" + id
    };
  },
  getPrintListData(params) {
    const attributes = ["startdate", "enddate", "client", "clientname",
      "status", "warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/printlistdata" + urlParams
    };
  },
  getRelatedClientAddressList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/client-address/list" + urlParams
    };
  },
  getRelatedClientList(params) {
    const attributes = ["search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/client/list" + urlParams
    };
  },
  getRelatedInvoicePriceList(params) {
    const attributes = ["item", "startdate", "enddate", "client", "clientname",
      "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/invoice-price/list" + urlParams
    };
  },
  getRelatedItemDetails(itemId) {
    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/item/details?item=" + itemId
    };
  },
  getRelatedStockList(params) {
    const attributes = ["warehouse", "search", "field"];
    const urlParams = App.Out.getParams(params, attributes);

    return {
      method: App.Enum.Axios.Method.Get,
      url: apiEndpoint + "/related/stock/list" + urlParams
    };
  },
  postData(data) {
    return {
      method: App.Enum.Axios.Method.Post,
      url: apiEndpoint,
      data: data
    };
  },
  putData(data) {
    return {
      method: App.Enum.Axios.Method.Put,
      url: apiEndpoint,
      data: data
    };
  }
}