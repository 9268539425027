import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import AppModuleType from "../../services/App/AppModuleType.js";
import Model from "./SlsModel.js";

export default {
  Dialog: {
    SearchItem: {
      Class: AppCatalog.ClassPrefix.Module + "-" + Model.Module.Class + "-" +
        Model.SalesItem.Module.Class +
        Model.SalesItem.ModuleType.SearchList.Class,
      Title: AppModuleType.Search.Name + " " + Model.SalesItem.Module.FullName +
        " dari " + Model.Module.FullName
    }
  },
  Export: {
    Details: {
      Excel: {
        // file
        SheetName: {
          Details: Model.ModuleType.Details.Name,
          InvoiceList: AppModuleType.List.Name + " " + AppModule.Invoice.FullName
        },
        // ui
        Title: AppModuleType.Export.Name + " " + AppModuleType.Details.Name + " " +
          Model.Module.FullName,
        Option: {
          Details: Model.ModuleType.Details.Name + " " + Model.Module.FullName,
          InvoiceList: AppModuleType.List.Name + " " + AppModule.Invoice.FullName
        }
      }
    },
    List: {
      Excel: {
        // file
        SheetName: {
          List: AppModuleType.List.FullName + " " + AppModule.Sales.FullName,
          ListByClient: AppModuleType.List.FullName + " " + 
            AppCatalog.Field.Qty.Label + " " + AppModule.Item.FullName
        },
        Option: {
          List: AppModuleType.List.FullName + " " + AppModule.Sales.FullName,
          SalesItem: "Dengan " + AppModule.Item.FullName,
          SalesItemListByClient: "Dengan " + AppCatalog.Field.Qty.Label + " " + AppModule.Item.FullName
        }
      }
    }
  },
  Message: {
    Details_ParamIncomplete:
      AppCatalog.Message.ParamIncomplete,
    Edit_ConfirmDeleteItem:
      "Yakin menghapus " + Model.SalesItem.Module.FullName + "?",
    New_ClientDiscPercent:
      AppCatalog.Field.Disc.Label + " " + AppModule.Client.Name +
      " Diterapkan",
    New_ConfirmDeleteItem:
      "Yakin menghapus " + Model.SalesItem.Module.FullName + "?"
  },
  Section: {
    Details: {
      Title: AppModuleType.Details.FullName
    },
    Invoice: {
      Title: AppModule.Invoice.FullName
    },
    RtnInvoice: {
      Title: AppModule.RtnInvoice.FullName
    },
    SalesItem: AppModuleType.List.Name + " " + AppModule.Item.FullName
  },
  Tabs: {
    Details: Model.ModuleType.Details.Name,
    InvoiceList: Model.Invoice.ModuleType.List.Name + " " +
      Model.Invoice.Module.FullName
  }
}