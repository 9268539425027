import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import InputType from "../../services/Input/InputType.js";
import OutputType from "../../services/Input/OutputType.js";

// see details: "@/services/Input/DataCatalog.js"
export default {
  Comment: {
    Label: AppCatalog.Field.Comment.Label,
    Type: AppCatalog.Field.Comment.Type,
    MaxLength: 100
  },
  CreatedByID: {
    Label: AppCatalog.Field.CreatedByID.Label,
    Type: AppCatalog.Field.CreatedByID.Type,
    RelatedValue: AppCatalog.Field.CreatedByID.RelatedValue,
    RelatedModule: AppCatalog.Field.CreatedByID.RelatedModule
  },
  Date: {
    Label: AppCatalog.Field.Date.Label,
    Type: AppCatalog.Field.Date.Type,
    Required: true,
    RequiredHide: true
  },
  DeliveryAddress: {
    Label: AppCatalog.Field.Address.Label + " Pengiriman",
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 200
  },
  DeliveryDate: {
    Label: AppCatalog.Field.Date.Label + " Pengiriman",
    Type: AppCatalog.Field.Date.Type
  },
  PONumber: {
    Label: AppCatalog.Field.Number.Label + " PO",
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true
  },
  PONumberConfirm: {
    Label: AppCatalog.Field.Number.Label + " PO",
    Type: AppCatalog.Field.Number.Type,
    MaxLength: 50,
    Highlight: true,
    Required: true
  },
  Status: {
    Label: AppCatalog.Field.Status.Label,
    Type: AppCatalog.Field.Status.Type,
    RelatedValue: "StatusName",
    ReadOnly: true
  },
  Total: {
    Label: AppCatalog.Field.Total.Label,
    Type: AppCatalog.Field.Total.Type,
    ReadOnly: AppCatalog.Field.Total.ReadOnly,
    Output: AppCatalog.Field.Total.Output
  },
  TotalItems: {
    Label: AppCatalog.Field.Total.Label + " " + AppModule.Item.FullName,
    HelpLabel: "item",
    Type: InputType.Integer,
    ReadOnly: true
  },
  Vendor: {
    Label: AppCatalog.Field.Name.Label,
    ExportListLabel: AppCatalog.Field.Name.Label + " " +
      AppModule.Vendor.FullName,
    Type: AppCatalog.Field.Name.Type,
    MaxLength: 200,
    Required: true,
  },
  VendorAddress: {
    Label: AppCatalog.Field.Address.Label + " " + AppModule.Vendor.FullName,
    PrintLabel: AppCatalog.Field.Address.Label,
    Type: AppCatalog.Field.Address.Type,
    MaxLength: 200,
    Highlight: true
  },
  VendorID: {
    Label: AppModule.Vendor.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Vendor.FullName,
    Type: AppCatalog.Field.ID.Type,
    ReadOnly: true,
    Highlight: true,
    RelatedValue: "Vendor",
    RelatedModule: AppModule.Vendor,
    Help: "Pilih dari master " + AppModule.Vendor.FullName + ", atau ketik manual."
  },
  WarehouseID: {
    Label: AppModule.Warehouse.FullName,
    ExportLabel: AppCatalog.Field.Name.Label + " " + AppModule.Warehouse.FullName,
    Type: AppCatalog.Field.ID.Type,
    Input: AppCatalog.Field.ID.Input,
    Required: true,
    RelatedValue: "WarehouseName",
    RelatedModule: AppModule.Warehouse,
    RelatedIsActive: "WarehouseIsActive"
  },
  // support
  IsAutoNumber: {
    Label: AppCatalog.Field.Number.Label + " PO",
    Type: AppCatalog.Field.IsAutoNumber.Type,
    Output: OutputType.Boolean.AutoManual
  },
  VendorAlias: {
    Label: AppCatalog.Field.Alias.Label,
    Type: AppCatalog.Field.Alias.Type,
    MaxLength: 20,
    ReadOnly: true
  },
  WarehouseName: {
    Label: AppModule.Warehouse.Name,
    Type: AppCatalog.Field.Name.Type,
    ReadOnly: true
  },
}