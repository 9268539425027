<template>
  <kst-page-details
    :actions="actions"
    @init="handleInit"
  >
    <b-tabs multiline v-model="activeTab" size="is-medium" type="is-boxed">
      <b-tab-item :label="tabs.Details">
        <Details :details="details"/>
        <Items :items="details.Items"/>
        <Total :details="details"/>
      </b-tab-item>
      <b-tab-item :label="tabs.InvoiceList" v-if="access.Invoice_Act_List">
        <InvoiceRelatedList date detailed ponumber warehouse
          :dataList="invoiceList"
          :salesId="details.ID"
          :specialPriceCount="specialPriceCount"
        />
      </b-tab-item>
    </b-tabs>
  </kst-page-details>
</template>

<script>
import RouterMixin from "../../../mixins/RouterMixin.js";
import Info from "../SlsInfo.js";
import Model from "../SlsModel.js";
import Services from "../../../services/Api/SalesServices.js";

import Details from "./SlsD_Details.vue";
import Items from "./SlsD_Items.vue";
import Total from "./SlsD_Total.vue";
import InvoiceRelatedList from "../../Invoice/RelatedList/InvRL.vue";

export default {
  mixins: [RouterMixin],
  components: {
    Details,
    Items,
    Total,
    InvoiceRelatedList
  },
  data: () => ({
    access: {},
    actions: [],
    details: {},
    invoiceList: [],
    specialPriceCount: 0,
    // tabs
    activeTab: 0,
    tabs: {
      Details: Info.Tabs.Details,
      InvoiceList: Info.Tabs.InvoiceList
    }
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadDetails) {
      this.resetData(id);

      const config = Services.getDetailsData(id);
      loadDetails(config, this.handleInit_success);
    },
    handleInit_success(data) {
      this.details = data.Details;
      Model.setDetailsByStatus(this.details);
      this.specialPriceCount = data.SpecialPriceCount
        ? data.SpecialPriceCount : 0;

      for (const item of this.details.Items) {
        Model.updateItemReadOnly(item);
      }

      if (this.access.Invoice_Act_List) {
        if (Object.prototype.hasOwnProperty.call(data, "InvoiceList")) {
          this.invoiceList = data.InvoiceList;
        }
        else {
          this.access.Invoice_Act_List = false;
        }
      }
    },

    /*** custom ***/

    resetData(id) {
      this.access = this.$kst.Access.create(Model.Access);
      this.actions = Model.Details.getActions(id, this.access);
      this.details = {};
      this.activeTab = 0;
      this.invoiceList = [];
      this.specialPriceCount = 0;
    }
  }
}
</script>